import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  useIntl,
  //Link,
  //FormattedMessage,
  //changeLocale,
} from "gatsby-plugin-react-intl";
import Slider from "react-slick";

const ComponentMain5 = ({ image, children }) => {
  const intl = useIntl();

  const [pos, setPos] = useState(0);
  const isActiveBenefit = () => {
    return pos === 0 ? "active" : "";
  };
  const isActiveScience = () => {
    return pos === 1 ? "active" : "";
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          centerMode: false,
        },
      },
    ],
  };
  return (
    <section className="sect sect_TreatmentCvd_iuh234 bg-white">
      <div className="container">
        <div className="container-block flex-row top-border-container">
          <div className="flex-column-12">
            <div className="flex-row top-border-wrap mb-8">
              <div className="flex-column-4 px-4">
                <div
                  className={"top-border " + isActiveBenefit()}
                  onClick={() => setPos(0)}
                  onKeyDown={() => setPos(1)}
                  role = "button"
                  tabIndex={0}
                >
                  {intl.locale === "en" ? `The Benefits` : `Die Vorteile`}
                </div>
              </div>
              <div className="flex-column-4 px-4">
                <div
                  className={"top-border " + isActiveScience()}
                  onClick={() => setPos(1)}
                  onKeyDown={() => setPos(1)}
                  role = "button"
                  tabIndex={0}
                >
                  {intl.locale === "en" ? `Sciences` : `Publikationen`}
                </div>
              </div>
            </div>
          </div>
          <div className="container-block-imgContainer flex-column-6">
            <StaticImage
              src="../../images/treatment/spinal/33.jpg"
              alt="ImgTreatment"
              loading="eager"
              className="Imgblock01"
            />
          </div>
          <div className="container-block-txtContainer flex-column-6">
            <div className="px-6 py-0">
              {pos === 0 ? (
                <div>
                  <h2 className="h3">
                    {intl.locale === "en"
                      ? `The neuromuscular feedback therapy leads to a significant gain in functional locomotion. Other effects which can be more or less pronounced include: `
                      : `Die neuromuskuläre Feedbacktherapie führt zu signifikanten Verbesserung der funktionellen Fortbewegung. Weitere Effekte die mehr oder weniger ausgeprägt sein können sind: `}
                  </h2>
                  {intl.locale === "en" ? (
                    <p>
                      • Reduction of neuropathic pains
                      <br />
                      • Improved bladder and bowl functions
                      <br />
                      • Positive improved spasticity symptoms
                      <br />
                      • Larger areas with skin sensation resulting in a lower
                      decubitus risk.
                      <br />
                      • Functional improvement and increase in mobility
                      <br />• Reduced need for assistive devices
                      <br />
                      • Increased walking speed and distance
                      <br />• Development of musculature
                    </p>
                  ) : (
                    <p>
                      • Abnahme neuropathischer Schmerzen
                      <br />
                      • Verbesserte Blasen- und Darmfunktion
                      <br />
                      • positive Entwicklung spastischer Symptome
                      <br />
                      • größere Bereiche mit Sensibilität der Haut und somit
                      Abnahme des Dekubitusrisikos
                      <br />
                      • Funktionelle Verbesserungen und Zunahme der Mobilität
                      <br />• Weniger Hilfsmittel werden benötigt
                      <br />
                      • Höhere Gehgeschwindigkeit und längere Gehstrecke
                      <br />• Aufbau von Muskulatur
                    </p>
                  )}
                </div>
              ) : (
                <div className="slide slide-single">
                  <Slider {...settings}>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `HAL training results in improved over-ground walking for
  patients with chronic spinal cord injury`
                          : `HAL-Training verbessert die Gehfähigkeit von Patienten mit chronsichen Rückenmarksverletzungen`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `Highly significant improvements of HAL-associated
  walking time, distance, and speed were noticed.
  Furthermore, significant improvements have been
  especially shown in the functional abilities outside of
  HAL for over-ground walking obtained in the 6MWT, TUG
  test, and the 10MWT, including an increase in the WISCI
  II score of three patients. Muscle strength (LEMS)
  increased in all patients accompanied by a gain of the
  lower limb circumferences.`
                          : `Signifikante Verbesserungen mit HAL bei Gehdauer, Distanz und Gehgeschwindigkeit. Des Weiteren zeigten sich signifikante Verbesserungen hinsichtlich der funktionellen Gehfähigkeiten ohne HAL im 6-Minuten-Gehtest, Timed Up and Go Test und 10-Meter-Test. Drei Patienten verbesserten den WISCI II Score. Die Muskelkraft (LEMS) nahm, einhergehend mit einem Zuwachs der Beinumfänge, bei allen Patienten zu.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `Voluntary driven exoskeleton as a new tool for
  rehabilitation in chronic spinal cord injury: a pilot
  study`
                          : `Voluntary driven exoskeleton as a new tool for rehabilitation in chronic spinal cord injury: a pilot study`}
                      </p>
                      <div>
                        <a
                          href="https://www.thespinejournalonline.com/article/S1529-9430(14)00350-7/fulltext"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Induce neuronal plasticity in the primary somatosensory
  cortex in patients with spinal cord injury`
                          : `Anregen der neuronalen Plastizität im primären somatosensorischen Kortex bei Patienten mit Rückenmarksverletzungen`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The findings suggest that Robotic-assisted bodyweight
  supported treadmill training with HAL® in SCI patients
  is capable of inducing cortical plasticity following
  highly repetitive, active locomotive use of paretic
  legs. While there was no significant correlation of
  excitability with walking parameters, brain areas other
  than S1 might reflect improvement of walking functions.`
                          : `Die Ergebnisse deuten darauf hin, dass robotergestütztes Gehtraining auf dem Laufband mit HAL bei Patienten mit Rückenmarksverletzungen beiträgt, die kortikale Plastizität durch wiederholte aktive Bewegung der gelähmten Beine anzuregen. Es gab keine signifikante Korrelation der Erregbarkeit mit Gehparametern, aber andere Gehirnareale als der somatosensorische Kortex könnten die Verbesserungen der Gehfähigkeit widerspiegeln.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `HAL® exoskeleton training improves walking parameters
  and normalizes cortical excitability in primary
  somatosensory cortex in spinal cord injury patients`
                          : `HAL® exoskeleton training improves walking parameters and normalizes cortical excitability in primary somatosensory cortex in spinal cord injury patients `}
                      </p>
                      <div>
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4545929/"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Significant improvement on walking ability for chronic
  incomplete and complete paraplegia`
                          : `Signifikante Verbesserung der Gehfähigkeit bei chronischer kompletter und inkompletter Querschnittslähmung`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The HAL-assisted treadmill training leads to functional
  improvements in chronic iSCI or cSCI, both in and out of
  the exoskeleton. An improvement of approximately 50% in
  the 10MWTsss and in gait endurance (6MinWT) can be
  expected from such training. The influences of SCI
  lesion level and age on functional outcome were
  nonsignificant in the present study. Older age (≥ 50
  years) may be associated with smaller improvements in
  the 10MWTsss.`
                          : `HAL-gestüztes Laufbandtraining führt zu funktionellen Verbeserrungen bei chronischen inkompletten und kompletten Querschnittslähmungen, sowohl mit dem Exoskelett als auch ohne Exoskelett. Eine Verbesserung um ca. 50% im 10-Meter-Test und der Gehausdauer (6-Minuten-Gehtest) kann erwartet werden. Die Einflüsse der Läsionshöhe des Querschnitts sowie des Zeitraumes seit der Verletzung auf die Funktionalität waren nicht signifikant. Ein höheres Alter(≥ 50 Jahre) kann mit einer geringeren Verbesserung im 10-Meter-Test einhergehen.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `Against the odds: what to expect in rehabilitation of
  chronic spinal cord injury with a neurologically
  controlled Hybrid Assistive Limb exoskeleton. A subgroup
  analysis of 55 patients according to age and lesion
  level`
                          : `Against the odds: what to expect in rehabilitation of chronic spinal cord injury with a neurologically controlled Hybrid Assistive Limb exoskeleton. A subgroup analysis of 55 patients according to age and lesion level`}
                      </p>
                      <div>
                        <a
                          href="https://thejns.org/focus/view/journals/neurosurg-focus/42/5/article-pE15.xml"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Significantly improved their functional and ambulatory
  mobility for Chronic Spinal Cord Injury patients`
                          : `Signifikante Verbesserungen der Funktionalität und Gangmobilität bei Patienten mit chronischen Rückenmarksverletzungen`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The HAL exoskeleton training enables effective, body
  weight-supported treadmill training and is capable of
  improving ambulatory mobility. Eighteen of the 21
  patients reported improvements of their bowel and
  bladder dysfunctions, and 2 of them were able to
  discontinue self-catheterization. Eight patients had
  spasticity % 4 according to the Ashworth scale, but this
  did not interfere with the course of the training.`
                          : `Das HAL-Exoskeletttraining ermöglicht effektives Laufbandtraining und kann die Gangmobilität verbessern. 18 von 21 Patienten gaben Verbesserungen bei Blasen- und Darmdysfunktionen an. Acht Patienten hatten Spastiken von 4% auf der Ashworth Skala, diese beeinträchtigten das Training jedoch nicht.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `Hybrid Assistive Limb Exoskeleton HAL in the
  Rehabilitation of Chronic Spinal Cord Injury: Proof of
  Concept; the Results in 21 Patients`
                          : `Hybrid Assistive Limb Exoskeleton HAL in the Rehabilitation of Chronic Spinal Cord Injury: Proof of Concept; the Results in 21 Patients`}
                      </p>
                      <div>
                        <a
                          href="https://www.sciencedirect.com/science/article/abs/pii/S1878875017318089?via%3Dihub"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `HAL-associated walking parameters and independent
  functional improvements were maintained in all the
  patients`
                          : `Verbesserung der Gangparameter durch HAL und unabhängige funktionelle Verbesserungen blieben bei allen Patienten erhalten`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `Long-term 1-year maintenance of HAL associated treadmill
  walking parameters and of improved independent walking
  abilities after initial 12 weeks of daily HAL-BWSTT is
  possible and depends mainly on the patients’ ambulatory
  status accomplished after initial training period.`
                          : `Langfristige (einjährige) Erhaltung der HAL-Laufbandparameter und verbesserte eigenständige Gehfähigkeit nach initialem zwölfwöchigen täglichen HAL-Training ist möglich und hauptsächlich von der Gehfähigkeit abhängig, die am Ende der initialen Trainingsperiode erreicht wurde.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `Functional Outcome of Neurologic-Controlled
  HAL-Exoskeletal Neurorehabilitation in Chronic Spinal
  Cord Injury: A Pilot With One Year Treatment and
  Variable Treatment Frequency`
                          : `Functional Outcome of Neurologic-Controlled HAL-Exoskeletal Neurorehabilitation in Chronic Spinal Cord Injury: A Pilot With One Year Treatment and Variable Treatment Frequency`}
                      </p>
                      <div>
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5722001/"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `The Study's results suggest that HAL improved the gait
  coordination of acute patients by supporting the
  relearning process and therefore reshaping their gait
  pattern.`
                          : `Die Studienergebnisse deuten darauf hin, dass der HAL die Gangkoordination bei akuten Patienten durch Unterstützung des Wiedererlernens, verbessert. Das Gangbild wird so neu gebildet.`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The Study found that HAL therapy improved the walking
  performance; the walking speed and stride length were
  increased, and the time and number of steps to cover 10
  m were decreased, in all acute and chronic patients.
  Positive effects were also found in the motor function
  scores; the BI and FIM scores were increased after HAL
  therapy for all acute patients`
                          : `Die HAL-Therapie verbessert die Gehfähigkeit. Bei allen akuten und chronischen Patienten erhöhte sich die Gehgeschwindigkeit und Schrittlänge, während die benötigte Zeit und Anzahl der Schritte für den 10-Meter-Test abnahmen. Weitere positive Effekte konnten auch bei motorischen Funktionswerten beobachtet werden. Der BI- und FIM Score erhöhte sich nach dem HAL-Training bei allen akuten Patienten.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `Effect of HAL therapy in the Ossification of the
  Posterior Longitudinal Ligament (OPLL) patients in acute
  and chronic stages after decompression surgery Reshaping
  of Gait Coordination by Robotic Intervention in
  Myelopathy Patients After Surgery`
                          : `"Effekt der HAL-Therape bei Patienten mit Ossifikation des hinteren Längsbandes (OPLL) in akuter und chronischer Phase nach Dekompressionsoperation
                          Reshaping of Gait Coordination by Robotic Intervention in Myelopathy Patients After Surgery 
                          "`}
                      </p>
                      <div>
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5840280/"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComponentMain5;
