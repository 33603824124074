import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Slider from "react-slick";
import ComponentMain2 from "../../components/main/component-main-2";
import ComponentTreatmentSpinal from "../../components/main/component-treatment-spinal";
import ComponentTextblock4 from "../../components/component-textblock-4";
import ComponentColumn2 from "../../components/component-column-2";
//import ComponentPics2 from "../../components/pics/component-pics-2";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TreatmentCvd = ({ data, location }) => {
  const intl = useIntl();

  //const [isToggleOpen, setToggleOpen] = useState([]);
  //const handleToggle = (e) => {
  //  setToggleOpen({
  //    ...isToggleOpen,
  //    [e.currentTarget.id]: isToggleOpen[e.currentTarget.id] ? false : true,
  //  });
  //};

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Layout location={location} locale={intl.locale} mode="beige">
      <Seo
        title={
          intl.locale === "en"
            ? `Revolutionary Robotic treatment for Spinal Cord Injury`
            : `Revolutionäre robotische Therapie bei Rückenmarksverletzungen`
        }
        description={
          intl.locale === "en"
            ? `Improvement of approximately 50% in the 10MWTs and in gait endurance (6MinWT) can be expected from Cybernics Treatment for chronic spinal cord injury. `
            : `Mit der Cybernic Therapie kann, bei chronischen Rückenmarksverletzungen, eine Verbesserung um ca. 50% im 10MWTs und in der Gehausdauer (6MinWT) erreicht werden.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/6MTQWtQljYvy02TENZtuDh/9c57be1c7de0539d60151d14901125a4/E178.jpg?w=1200&h=630&fit=fill"
      />

      <ComponentMain2
        title={
          intl.locale === "en"
            ? `Improve functional locomotion`
            : `Verbessern der Gehfähigkeit`
        }
        label={
          intl.locale === "en"
            ? `Spinal cord injury`
            : `Rückenmarksverletzungen`
        }
        content={
          intl.locale === "en"
            ? `Locomotor training performed using a neurologically controlled cyborg HAL enables the neural activity and repetitive excercises of specific tasks.. HAL Therapy promotes learning and leads to the restructuring of appropriate proprioceptive feedback loop.`
            : `Lokomotionstraining mit dem neurologisch kontrollierten Cyborg HAL ermöglicht neuronale Aktivität und wiederholte Durchführung bestimmter Aufgaben. Die HAL- Therapie fördert das Lernen und führt zur Rekonstruktion einer angemessenen propriozeptiven Feedback-Schleife. `
        }
        image="/images/treatment/spinal/29.jpg"
      />

      <ComponentTextblock4
        content={
          intl.locale === "en"
            ? `"The HAL-assisted treadmill training leads to functional improvements in chronic iSCI or cSCI. An improvement of approximately 50% in the 10MWTs and in gait endurance (6MinWT) can be expected from such training" (Grasmücke et al., 2017).`
            : `"Das HAL gestützte Laufbandtraining führt zu funktionellen Verbesserungen bei chronisch inkompletten und kompletten Querschnittspatienten. Eine Verbesserung von ca. 50% im 10m Gehtest (10MWTs) und der Gehausdauer (6MinWT) kann von solch einem Training erwartet werden" (Grasmücke et al., 2017).`
        }
      />

      <section className="bg-white">
        <div className="container">
          <div className="flex-row mx-n2 mb-6 pict-3">
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/spinal/30.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/spinal/31.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/spinal/32.jpg"
                className="mb-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_third bg-white">
        <div className="container">
          <ComponentColumn2
            lefttitle={
              intl.locale === "en"
                ? `Make Sense in Neurorehabilitation`
                : `Sinnvolle Neurorehabilitation`
            }
            leftcontent={
              intl.locale === "en"
                ? `HAL, Cyberdyne's Hybrid Assistive Limb, improves locomotor activity in spinal cord injury. This neurorehab device helps the patients move their paretic or paralyzed legs based on their intention and the device does not dictate its movements on its own. Using the feedback system inherent in your body, your voluntary movements assisted by HAL will lead to improvements in your gait patterns and walking function even in the chronic stages of the disease. Furthermore, positive effects have been observed with regard to neuropathic pain, bladder and bowel function, spasticity, mental health and the cardiovascular system.`
                : `HAL, Cyberdyne's Hybrid Assitive Limb (HAL), verbessert die lokomotorische Aktivität bei Rückenmarksverletzten. Das neurologisch rehabilitative Produkt hilft den Patienten, gelähmte Beine basierend auf der eigenen Intention wieder zu bewegen, wobei  HAL die Bewegung nicht selbst bestimmt. Indem Sie die das körpereigene Feedbacksystem nutzen, wird Ihre willentliche Bewegung mit Unterstüzung von HAL zu Verbesserungen Ihres Gangbildes und Ihrer Gehfähigkeit führen, und zwar auch im chronischen Stadium der Krankheit. Des Weiteren konnten Verbesserungen hinsichtlich neuropathischer Schmerzen, Blasen- und Darmfunktion, Spastiken, mentaler Gesundheit und des kardiovaskulären Systems beobachtet werden.`
            }
            label=""
            list={
              intl.locale === "en"
                ? [
                    "HAL is designed to establish a pathway for signals that reach the muscles to cause a meaningful response through movement",
                    "Various setting parameters adjustments are also available for medical professionals to help the wearer achieve their intended movements more easily",
                    "HAL's non-invasive sensors can detect faint “bio-electrical signals” that reflect the wearer’s intention on the surface of their skin.",
                  ]
                : [
                    "Der HAL ist so entwickelt worden, dass er eine Leitungsbahn für die Signale, die den Muskel erreichen, bilden soll und als Ergebnis eine bedeutsame Antwort durch die Bewegung generiert werden kann.",
                    "Eine medizinische Fachkraft kann verschiedene Einstellungen wählen und dem Träger es so ermöglichen die angedachte Bewegung leicht auszuführen.",
                    "Die nicht-invasiven Sensoren des HALs können kleinste bioelektrische Signale, die die Absicht des Trägers widerspiegeln, erkennen.",
                  ]
            }
          />
        </div>
      </section>

      <ComponentTreatmentSpinal
        image="/images/treatment/treatment-slider-img-1.png"
      />

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en" ? `Videos` : `Videos`}
          </p>
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Watch the video to see how Cybernics Technology is applied in
  therapy.`
              : `Schauen Sie sich das Video an und sehen Sie, wie die Cybernics Technologie in der Therapie angewendet wird.`}
          </p>
          <div className="slide">
            <Slider {...settings}>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Ocv7yBrHD0c"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Walking on a treadmill with HAL Lower Limb`
                      : `Gehen auf dem Laufband mit dem HAL Lower Limb`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/xn2a_It3Yu4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `HAL Lower Limb Training while sitting`
                      : `Training mit dem HAL Lower Limb im Sitzen`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/4yYyDBujLfQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Overcoming obstacles with HAL Lower Limb`
                      : `Überwinden von Hindernissen mit dem HAL Lower Limb`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/a2mTWFjPnmk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Knee extension training with HAL Single Joint`
                      : `Training der Kniestreckung mit dem HAL Single Joint`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/YyLqfxwcQ4w"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Training in supine position with HAL Single Joint (ankle)`
                      : `Training mit dem HAL Single Joint in Rückenlage (Sprunggelenk)`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HYxQGCG-jyE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Squats with HAL Lumbar Type`
                      : `Kniebeugen mit dem HAL Lumbar Type`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/CZuRsaui_Vs"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Dynamic exercise with HAL Lumbar Type`
                      : `Dynamisches Training mit dem HAL Lumbar Type`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/k7uWSuSczOA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Elbow training with HAL Single Joint`
                      : `Training des Ellbogens mit dem HAL Single Joint`}
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about HAL Treatment`
              : `Mehr über das HAL Training erfahren`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cerebrovascular disease`
                    : `Zerebrovaskulären Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `From the perspective of motor learning, repetitive movement
  and voluntary exercise are important elements`
                    : `Aus Sicht des motorischen Lernen sind wiederholte Bewegungen und willkürlich durchgeführte Aufgaben wichtige Elemente`}
                </p>
                <Link to="/treatment/cvd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Progressive neuromuscular diseases`
                    : `Progressive neuromuskuläre Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The 5 years Post-marketing surveillance results suggest that
  treatment with the HAL system shows improvement in gait
  related outcome measures despite the progressive nature of the
  diseases.`
                    : `Beobachtungen 5 Jahre nach Einführung des HALs zeigen, dass die HAL-Therapie zu Verbesserungen in Testungen in Bezug auf die Gehfähigkeit führt, trotz der fortschreitenden Art der Erkrankung.`}
                </p>
                <Link to="/treatment/pnd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `About ZNB - Our Therapist`
                    : `Das ZNB / Unsere Therapeuten`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Meet our team. We offer exceptional level of personalised
  skill and expertise.`
                    : `Treffen Sie unser Team. Wie bieten ein außergewöhnliches Level an individuellen Fähigkeiten und Fachwissen. `}
                </p>
                <Link to="/treatment/znb/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Testimonials` : `Erfahrungsberichte`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Discover real stories from real people about how Cyberdyne
  technology changed their lives forever.`
                    : `Entdecken Sie reale Geschichten von Patienten darüber wie Cyberdyne deren Leben für immer verändert hat.`}
                </p>
                <div>
                  <Link to="/treatment/testimonials/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Frequently Asked Questions`
                    : `Häufig gestellte Fragen (FAQ)`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Here are our general frequently asked questions. For more
  specific questions, please contact us directly.`
                    : `Hier finden Sie häufig gestellte Fragen. Für spezifische Fragen kontaktieren Sie uns bitte direkt.`}
                </p>
                <div>
                  <Link to="/treatment/faq/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `HAL— Miracle in Motion`
              : `HAL - Wunderwerk in Bewegung`}
          </p>
          <p>
            {intl.locale === "en"
              ? `Healthcare providers want the best for their patients and staff. HAL
            can help.`
              : `Gesundheitsdienstleister wollen nur das beste für Ihre Patienten und Mitarbeiter. HAL kann Ihnen dabei helfen.`}
          </p>
          <div>
            <Link to="/contact/" className="link-button">
              {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TreatmentCvd;
